export default [
  {
    icon: 'PieChartIcon',
    title: 'Reports',
    children: [

      // เมนูหมวด PowerBI
      {
        icon: 'PieChartIcon',
        title: 'Sales Performance (TH)',
        route: 'powerbi-sales-performance-th',
        resource: 'Sales Performance (TH)',
        action: 'Access',
      },
      {
        icon: 'PieChartIcon',
        title: 'Sales Performance (ID)',
        route: 'powerbi-sales-performance-id',
        resource: 'Sales Performance (ID)',
        action: 'Access',
      },
      {
        icon: 'PieChartIcon',
        title: 'Gross Profit By Account',
        route: 'powerbi-gross-profit-by-account',
        resource: 'Gross Profit By Account',
        action: 'Access',
      },
      {
        icon: 'PieChartIcon',
        title: 'Payment Calendar',
        route: 'powerbi-payment-calendar',
        resource: 'Payment Calendar',
        action: 'Access',
      },
      {
        icon: 'PieChartIcon',
        title: 'Campus Point Tracking',
        route: 'powerbi-campus-point-tracking',
        resource: 'Campus Point Tracking',
        action: 'Access',
      },
      {
        icon: 'PieChartIcon',
        title: 'Benchmark By Industry',
        route: 'powerbi-benchmark-by-industry',
        resource: 'Benchmark By Industry',
        action: 'Access',
      },

      // เมนูหมวด Google Sheet

      {
        icon: 'FileTextIcon',
        title: 'Precautions Warning',
        route: 'google-sheet-precautions-warning',
        resource: 'Precautions Warning',
        action: 'Access',
      },

      {
        icon: 'FileTextIcon',
        title: 'Iconic - Campaign Performance (Active Cycle)',
        route: 'google-sheet-iconic-campaign-performance-active-cycle',
        resource: 'Iconic - Campaign Performance',
        action: 'Access',
      },

      {
        icon: 'FileTextIcon',
        title: 'PMKT Bi-Weekly MOM',
        route: 'google-sheet-pmkt-bi-weekly-mom',
        resource: 'PMKT Bi-Weekly MOM',
        action: 'Access',
      },

      {
        icon: 'FileTextIcon',
        title: 'Missing ACI',
        route: 'google-sheet-missing-aci',
        resource: 'Missing ACI',
        action: 'Access',
      },

      {
        icon: 'FileTextIcon',
        title: 'Monthly PK Report',
        route: 'google-sheet-monthly-pk-report',
        resource: 'Monthly PK Report',
        action: 'Access',
      },
    ],
  },
]
